/**
 * Renders a GTM Text Block into a section
 * nice to have: maybe make this generic for future types?
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import { Heading2 } from '../../atoms/Headings/Headings';
import StatsCounter from '../../molecules/StatsCounter/StatsCounter';
import PageWrapper from '../PageWrapper/PageWrapper';
import Section from '../../molecules/Section/Section';
import {
  colors,
  fontSizes,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';

const StatsCounterSection = ({
  backgroundImage,
  headingText,
  sectionStyles,
  statsCounterBlocks,
}) => {
  const statsCounterSectionCss = css`
    margin: 0 auto;
    background-color: ${sectionStyles && sectionStyles.backgroundColor
      ? sectionSettings[sectionStyles.backgroundColor]
      : colors.white};
    background-size: contain;
  `;

  let statsCounterBackgroundCss = {};
  if (backgroundImage) {
    statsCounterBackgroundCss = css`
      background-color: transparent;
      background-image: url(${sanityImage(backgroundImage)
        .auto('format')
        .width(1500)
        .fit('max')
        .url()});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    `;
  }

  const gridCss = css`
    > * {
      padding: ${uc('20px')};
    }
  `;

  const heading2Css = css`
    max-width: ${uc('900px')};
    margin: ${uc('0 auto 80px auto')};
    text-align: center;
  `;

  const renderedStatsCounterBlocks = statsCounterBlocks.map(block => (
    <StatsCounter key={`stats-counter-component-${block._key}`} {...block} />
  ));

  return (
    <Section
      css={[statsCounterSectionCss, statsCounterBackgroundCss]}
      sectionStyles={sectionStyles}
    >
      <PageWrapper>
        <Heading2 fontSize={fontSizes.thirty} css={heading2Css}>
          {headingText}
        </Heading2>
        <DeprecatedGrid css={gridCss}>
          {renderedStatsCounterBlocks}
        </DeprecatedGrid>
      </PageWrapper>
    </Section>
  );
};

/**
 * Prop types
 */
StatsCounterSection.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  headingText: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  statsCounterBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

StatsCounterSection.defaultProps = {
  backgroundImage: false,
  headingText: '',
  sectionStyles: {},
};

export default StatsCounterSection;
