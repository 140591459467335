import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useCountUp } from 'react-countup';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading3, Heading4 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import Link from '../../atoms/Link/Link';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';

const StatsCounter = ({
  description,
  link,
  prefixText,
  statsNumber,
  subheadingText,
  suffixText,
}) => {
  // initialize countUp
  const { countUp, start } = useCountUp({
    start: 0,
    end: statsNumber,
    duration: 2,
  });

  // initialize scrolling code
  const initScrollMagic = async () => {
    const module = await import('scrollmagic');
    const ScrollMagic = module.default;
    const scrollMagicController = new ScrollMagic.Controller();

    new ScrollMagic.Scene({
      triggerElement: '.stats-counter',
      triggerHook: 'onEnter',
      reverse: false,
    })
      .on('start', () => {
        // start count upon entering
        start();
      })
      .setClassToggle('.stats-counter', 'activated')
      .addTo(scrollMagicController);
  };

  // add bracket at end to avoid useEffect rendering multiple times
  useLayoutEffect(() => {
    initScrollMagic();
  }, []);

  const statsCounterCss = css`
    flex: 1 0 33%;
    width: ${uc('305px')};
    text-align: center;
    vertical-align: top;

    @media (${breakpoints.mobile}) {
      flex: 1 100%;
    }
  `;

  const statsNumberCss = css`
    display: inline-block;
    max-width: ${uc('425px')};
    margin-bottom: ${uc('-32px')};
    font-size: ${uc('110px')};
    opacity: 0;
    transition: 4s cubic-bezier(0, 0.61, 0, 0.97);

    &::after {
      font-size: ${suffixText === '%' ? uc('66px') : uc('110px')};
      animation: stats-fade-up-and-in forwards;
      animation-play-state: paused;
      content: '${suffixText}';
      ${suffixText === '%' ? 'position: absolute;' : ''}
      ${suffixText === '%' ? `top: ${uc('13px')};` : ''}
    }

    &.activated {
      transform: translateY(calc(-100% + ${uc('128px')}));
      opacity: 1;

      &::after {
        opacity: 1;
        animation-play-state: running;
      }
    }

    @keyframes stats-fade-up-and-in {
      0% {
        transform: translate(0, ${uc('32px')}) scale(0.8);
        opacity: 0;
      }

      100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
      }
    }

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.sixty};

      &::after {
        top: 0;
        font-size: ${fontSizes.sixty};
      }

      &.activated {
        transform: translateY(calc(-100% + ${uc('64px')}));
        opacity: 1;
      }
    }

    @media (${breakpoints.mobile}) {
      margin-bottom: 0;
      font-size: ${fontSizes.sixty};

      &::after {
        position: initial;
        font-size: ${fontSizes.sixty};
      }

      &.activated {
        transform: none;
      }
    }
  `;

  const prefixTextCss = css`
    float: left;
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.thirtyEight};
    font-family: ${fontFamilies.proximaSoft};
    transform: translate(${uc('95px, 44px')});

    @media (${breakpoints.desktop}) {
      transform: translate(${uc('22px, 44px')});
    }

    @media (${breakpoints.tablet}) {
      float: none;
      min-height: ${uc('57px')};
      transform: none;
    }

    @media (${breakpoints.mobile}) {
      min-height: auto;
    }
  `;

  const descriptionTextCss = css`
    font-weight: ${fontWeights.light};
    font-size: ${fontSizes.twentyFive};
    font-family: ${fontFamilies.roboto};
    letter-spacing: 0;

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.twenty};
    }
  `;

  const linkCss = css`
    color: ${colors.mediumGray};
    font-family: ${fontFamilies.proximaSoft};

    &:active,
    &:focus,
    &:hover {
      color: ${colors.darkGray.three};
    }
  `;

  return (
    <div className="stats-counter" css={statsCounterCss}>
      <Text css={prefixTextCss} color={colors.primary}>
        {prefixText}
      </Text>
      <Heading3
        className="stats-counter"
        css={statsNumberCss}
        color={colors.primary}
      >
        {countUp}
      </Heading3>
      <Heading3>{subheadingText}</Heading3>
      <Heading4 color={colors.darkGray.three} css={descriptionTextCss}>
        {description}
      </Heading4>
      {link.link.length > 0 && (
        <Link css={linkCss} to={link}>
          {link.text}
        </Link>
      )}
    </div>
  );
};

StatsCounter.propTypes = {
  prefixText: PropTypes.string,
  subheadingText: PropTypes.string,
  statsNumber: PropTypes.number,
  suffixText: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.shape({ link: [], text: PropTypes.string }),
};

StatsCounter.defaultProps = {
  prefixText: '',
  subheadingText: '',
  statsNumber: 0,
  suffixText: '%',
  description: '',
  link: null,
};

export default StatsCounter;
